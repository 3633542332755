import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../Styles/_components/_login.scss';
import { userService } from '../../_services';
import { useTranslation } from 'react-i18next';

export function NewPasswordForm(props) {
    const [inputs, setInputs] = useState({
        password: ''
    });

    const { t } = useTranslation("common");
    let loc = useLocation();
    const accessCode = loc.search.slice(3)
    const { password, confirmPassword } = inputs;
    const [error, setError] = useState();
    const [passwordUpdated, setPasswordUpdated] = useState();
    const [submitted, setSubmitted] = useState(false);

    function setNewPassword(e) {
        e.preventDefault();
        setSubmitted(true);
        if (password !== confirmPassword) {
            setError(t('passwordReset.no-match'))
        } else {
            if (password && accessCode) {
                userService.accessCodePasswordReset(accessCode, password).then(user => {
                    if (!user) {
                        setError(t("passwordReset.reset-expired"));
                    } else {
                        setPasswordUpdated(true);
                    }
                })
            }
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    return <div className="login-form">
        <div className="login-logo">
            <div className='welcome'>{t("passwordReset.reset-password")}</div>
            <div className='welcome-msg'>{t("passwordReset.request-password-reset-subtitle")}</div>
            {error ? <div className='password-error'>
                <span>{error}</span>
            </div> : null}
        </div>
        {!passwordUpdated ?
            <form className="form" onSubmit={setNewPassword}>
                <div className="form-group">
                    <label>{t("passwordReset.enter-your-new-password")}</label>
                    <input type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                </div>
                <div className="form-group">
                    <label>{t("passwordReset.confirm-your-password")}</label>
                    <input type="password" name="confirmPassword" value={confirmPassword} onChange={handleChange} className={'form-control' + (submitted && !confirmPassword ? ' is-invalid' : '')} />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary">
                        {t("passwordReset.reset-password")}
                    </button>
                </div></form>
            : <div>
                <h2 className="text-center">{t("passwordReset.password-changed-successfully")}</h2>
                <Link className='reset' onClick={() => props.setSection("login")}>{t("passwordReset.return-to-login")}</Link>
            </div>
        }</div>
}