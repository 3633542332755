import { useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../../../Styles/dashboard.scss';
import { AppointmentStatus } from '../../../_enums/AppointmentStatus';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, LabelList, YAxis } from 'recharts';
import { useLoader } from 'src/_hooks/_loaders/useLoader';
import { DateTime } from 'luxon';
import { Feature, tenantService } from '../../../_services/tenant.service';

export default function StatisticsCard() {
    const [inStoreStats, setInStoreStats] = useState([]);
    const [virtualStats, setVirtualStats] = useState([]);
    const [inStoreStatsCancelled, setInStoreStatsCancelled] = useState([]);
    const [virtualStatsCancelled, setVirtualStatsCancelled] = useState([]);
    const [instoreMax, setInstoreMax] = useState(0);
    const [virtualMax, setVirtualMax] = useState(0);
    const currentUserId = useSelector(state => state.authentication.user.id);
    const currentUserName = useSelector(state => state.authentication.user.name);
    const tenantId = useSelector(state => state.authentication.user.activeTenant.id);
    const [features, setFeatures] = useState([]);
    const { t } = useTranslation("common");
    const now = DateTime.now();

    const bom = now.startOf('month');
    const eom = now.endOf('month');

    const { Loader, loading, response } = useLoader({
        path: "appointment",
        options: {
            searchFields: ['start<', 'end>'],
            searchValues: [eom.toISO(), bom.toISO()],
            pageSize: 1000,
            include: ["videocall.participants", "staff"]
        }
    })

    useEffect(() => {
        if (response && response.items)
            processStatistics(response.items);

    }, [loading]);

    useEffect(() => {
        tenantService.getFeatures(tenantId).then(setFeatures);
    }, []);

    function processStatistics(appointments) {
        const userAppointments = appointments.filter(app => app.staff.some(s => s.userId === currentUserId));
        const globalAppointments = appointments;

        const inStore = {
            userConfirmed: userAppointments.filter(app => !app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            globalConfirmed: globalAppointments.filter(app => !app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            userCancelled: userAppointments.filter(app => !app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
            globalCancelled: globalAppointments.filter(app => !app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
        };
        const virtual = {
            userConfirmed: userAppointments.filter(app => app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            globalConfirmed: globalAppointments.filter(app => app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            userCancelled: userAppointments.filter(app => app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
            globalCancelled: globalAppointments.filter(app => app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
        };

        setInStoreStats([
            { name: t("dashboard.confirmed-in-store"), User: inStore.userConfirmed, Global: inStore.globalConfirmed }
        ]);
        setInStoreStatsCancelled([
            { name: t("dashboard.cancelled-in-store"), User: inStore.userCancelled, Global: inStore.globalCancelled }
        ]);
        setVirtualStats([
            { name: t("dashboard.confirmed-virtual"), User: virtual.userConfirmed, Global: virtual.globalConfirmed }
        ]);
        setVirtualStatsCancelled([
            { name: t("dashboard.cancelled-virtual"), User: virtual.userCancelled, Global: virtual.globalCancelled }
        ]);
        setInstoreMax(Math.max(inStore.userConfirmed, inStore.globalConfirmed, inStore.userCancelled, inStore.globalCancelled));
        setVirtualMax(Math.max(virtual.userConfirmed, virtual.globalConfirmed, virtual.userCancelled, virtual.globalCancelled));
    }

    const barProps = { radius: [10, 10, 0, 0], }
    const userBarProps = { ...barProps, dataKey: "User", fill: "#61C4FF", stackId: "a" }
    const globalBarProps = { ...barProps, dataKey: "Global", fill: "#F40E6F", stackId: "b" }
    const labelProps = { fontSize: '16px', fontStyle: 'normal', fontWeight: 700, lineHeight: 'normal', position: "top" }
    const userLabelProps = { ...labelProps, fill: '#1E9CE8' }
    const globalLabelProps = { ...labelProps, fill: '#F40E6F' }
    const instoreYProps = { hide: true, domain: [0, instoreMax * 1.2] }
    const virtualYProps = { hide: true, domain: [0, virtualMax * 1.2] }

    return (
        <Tabs defaultActiveKey={features && features.includes(Feature.Appointments) ? "inStore" : "virtual"} id="statistics-tabs" className="mb-3">
            {features && features.includes(Feature.Appointments) ?
                <Tab eventKey="inStore" title={t("dashboard.in-store")} className=''>
                    {!loading && <Card className='dashboard-statistics-card'>
                        <Card.Body className='group-charts'>
                            <ResponsiveContainer width="100%" height={180} className={"inStoreStats"}>
                                <BarChart data={inStoreStats} width={200} height={180}>
                                    <XAxis dataKey="name" />
                                    <YAxis {...instoreYProps}></YAxis>
                                    <Tooltip />
                                    <Bar {...userBarProps} name="userConfirmed" >
                                        <LabelList dataKey="User" {...userLabelProps} />
                                    </Bar>
                                    <Bar {...globalBarProps} name="globalConfirmed" >
                                        <LabelList dataKey="Global" {...globalLabelProps} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                            <ResponsiveContainer width="100%" height={180} className={"inStoreStatsCancelled"}>
                                <BarChart data={inStoreStatsCancelled} width={200} height={180}>
                                    <XAxis dataKey="name" />
                                    <YAxis {...instoreYProps}></YAxis>
                                    <Tooltip />
                                    <Bar {...userBarProps} name="User" >
                                        <LabelList dataKey="User" {...userLabelProps} />
                                    </Bar>
                                    <Bar {...globalBarProps} name="Global" >
                                        <LabelList dataKey="Global" {...globalLabelProps} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                        <div className='small-index'>
                            <span className='personal-stats'><span className='personal-colour'></span>{currentUserName}</span><span className='global-stats'><span className='global-colour'></span>{t("dashboard.global")}</span>
                        </div>
                    </Card>}
                    {loading && <Loader />}
                </Tab>
                : null}
            <Tab eventKey="virtual" title={t("dashboard.virtual")} className='h-100'>
                {!loading && <Card className='dashboard-statistics-card'>
                    <Card.Body className='group-charts'>
                        <ResponsiveContainer width="100%" height={180} className={"virtualStats"}>
                            <BarChart data={virtualStats} width={200} height={180}>
                                <XAxis dataKey="name" />
                                <YAxis {...virtualYProps}></YAxis>
                                <Tooltip />
                                <Bar {...userBarProps} name="userConfirmed" >
                                    <LabelList dataKey="User"  {...userLabelProps} />
                                </Bar>
                                <Bar {...globalBarProps} name="globalConfirmed" >
                                    <LabelList dataKey="Global" {...globalLabelProps} />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer width="100%" height={180} className={"virtualStatsCancelled"}>
                            <BarChart data={virtualStatsCancelled} width={200} height={180}>
                                <XAxis dataKey="name" color='#F40E6F' />
                                <YAxis {...virtualYProps}></YAxis>
                                <Tooltip />
                                <Bar {...userBarProps} name="User" >
                                    <LabelList dataKey="User"{...userLabelProps} />
                                </Bar>
                                <Bar {...globalBarProps} name="Global" >
                                    <LabelList dataKey="Global" {...globalLabelProps} />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Card.Body>
                    <div className='small-index'>
                        <span className='personal-stats'><span className='personal-colour'></span>{currentUserName}</span><span className='global-stats'><span className='global-colour'></span>{t("dashboard.global")}</span>
                    </div>
                </Card>}
                {loading && <Loader />}
            </Tab>

        </Tabs>
    );
}
