import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { apiService } from './api.service';

export const Feature = {
    CRM: "crm",
    Call: "call",
    Users: "users",
    Tenant: "tenant",
    Shop: "shop",
    Cta: "cta",
    Appointments: "appointments",
    Orders: "orders",
    Integrations: "integrations",
    Journeys: "Journeys",
    Notes: "notes",
    EmailTemplates: "emailTemplates",
    Audit: "audit",
    Reports: "reports",
    Tenants: "tenants",
    Brand: "brand",
    SectionTemplate: "sectionTemplate",
    Product: "product",
    Media: "media",
    Tags: "tags",
    CtaMessages: "cta-messages"
}

const features = [Feature.CRM, Feature.Call, Feature.Users, Feature.Tenant, Feature.Shop, Feature.Cta, Feature.Appointments, Feature.Orders, Feature.Integrations, Feature.Journeys, Feature.Notes, Feature.EmailTemplates, Feature.Audit, Feature.Reports, Feature.Tenants, Feature.Brand, Feature.SectionTemplate, Feature.Product, Feature.Media, Feature.Tags, Feature.CtaMessages] // Don't change the order
export const tenantService = {
    searchTenants,
    deleteTenant,
    updateTenant,
    addTenant,
    getTenantById,
    getAccountUsage,
    getHost,
    getFeatures,
    setupTenant
}

function getTenantById(id, includes) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/tenants/${id}?`;
    if (includes) {
        url += `include=${includes}`
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(tenants => {
            return tenants
        })
}

async function getFeatures(tenantId) {
    return await apiService.getItems('account', { include: ["servicePlan"], searchField: "tenantId", searchValue: tenantId })
        .then(({ items }) => {
            const featureList = items[0].servicePlan.features
            const list = features.map((feature, i) => (featureList & (1 << i)) != 0 ? feature : false).filter(Boolean)
            return list
        }) || []
}

function getHost(host) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/tenants/home?s=${host}`, requestOptions)
        .then(handleResponse)
        .then(tenants => {
            return tenants
        })
}

function searchTenants(options) {
    return apiService.getItems('tenants', options).then(tenants => tenants)
}

function deleteTenant(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tenants/${id}`, requestOptions)
        .then(handleResponse);
}

function getAccountUsage(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tenants/${id}/account`, requestOptions)
        .then(handleResponse);
}

function addTenant(tenant) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tenant)
    }
    return fetch(`${config.apiUrl}/tenants`, requestOptions)
        .then(handleResponse)
}

function setupTenant(setupOptions, tenantToken) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'x-authorization-token': tenantToken ,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(setupOptions)
    }
    return fetch(`${config.apiUrl}/tenants/setup`, requestOptions)
        .then(handleResponse)
}

function updateTenant(tenant) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tenant)
    }
    return fetch(`${config.apiUrl}/tenants`, requestOptions)
        .then(handleResponse)
}