import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap'; // Import Modal and Button
import { useSelector } from 'react-redux';
import { tenantService } from '../../../_services';
import { accountService } from '../../../_services/accounts.service';
import '../../../Styles/dashboard.scss';
import './accounts.scss';
import { useTranslation } from 'react-i18next';
import PricingModal from './PricingModal';

export default function AccountsPlan() {
    const user = useSelector(state => state.authentication.user);

    const [usage, setUsage] = useState();
    const [plan, setPlan] = useState();
    const [account, setAccount] = useState();
    const tenantId = user.activeTenant.id;

    useEffect(() => {
        tenantService.getAccountUsage(tenantId).then((data) => setUsage(data));
    }, []);

    useEffect(() => {
        if (account) {
            console.log("Got the account", account);
            setPlan(account.servicePlan);
        }
    }, [account]);

    useEffect(() => {
        if (usage) {
            console.log("Got the usage", usage);
            accountService.getAccount(usage.id, ['servicePlan']).then(setAccount);
        }
    }, [usage]);
    return <AccountsUsage account={account} plan={plan} usage={usage?.usage} />
}

export function AccountsUsage(props) {

    const { account, plan, usage } = props;
    const [storageUsed, setStorageUsed] = useState();
    const [storageMax, setStorageMax] = useState();
    const { t } = useTranslation("common");

    useEffect(() => {
        if (account) {
            FirstUse();
        }
    }, [account])

    useEffect(() => {
        if (usage) {
            setStorageUsed(formatBytes(usage.assetbytes));
        }
    }, [usage])

    useEffect(() => {
        if (plan) {
            setStorageMax(formatBytes(plan.maxAssetBytes));
        }
        console.log("Plan", plan);

    }, [plan])

    const [showModal, setShowModal] = useState(false);

    function handleShow() {
        setShowModal(true);
    }

    function handleClose() {
        setShowModal(false);
    }

    function FirstUse() {
        if (account.newAccount) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        let numberFormatter = new Intl.NumberFormat(window.navigator.languages[0], { style: 'decimal' });
        return numberFormatter.format(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + ' ' + sizes[i];
    }

    function UsageItem({ title, count, max, suffix = true, empty, icon }) {
        const loadingImg = <img
            width={15}
            height={15}
            alt="Loading spinner"
            src="/loading.gif"
        />;
        return (
            <div className="usage account">
                {icon && <div className='icon-wrapper'>
                    {icon}
                </div>}
                <div>
                    <label className="usage-title">{title}</label>
                    {!empty && <div className="usage-value">
                        {count !== null ? count : loadingImg} used of {max !== null ? max : loadingImg} {suffix ? title : ""}
                    </div>}
                    {empty && <div className="usage-value"></div>}
                </div>
            </div>
        );
    }

    return (
        <div className='card-holder col-12 h-100'>
            <Card className='h-100'>
                <div className="dashboard-card-sm-title">{t("account.plan-details")}</div>
                <div className='free-plan'>
                    <div>
                        <span>Current Plan</span>
                        <h5>{plan?.name} Plan</h5>
                    </div>
                    <button onClick={handleShow}>
                        Change Plan
                    </button>
                </div>

                <Card.Body>
                    <div className="plan-counter justify-content-center justify-content-md-start">
                        <UsageItem title="Users" icon={<i className='material-symbols-outlined'>person</i>} count={usage ? usage.users : null} max={plan ? plan.maxUsers : null} />
                        <UsageItem title="Minutes" icon={<i className='material-symbols-outlined'>timer</i>} count={account ? account.minutesBalance.toFixed(2) : null} max={plan ? plan.minutes : null} />
                        <UsageItem title="Appointments" icon={<i className="fas fa-calendar-alt"></i>} count={usage ? usage.appointments : null} max={plan ? plan.maxAppointments : null} />
                        <UsageItem title="Assets" icon={<i className='material-symbols-outlined'>gallery_thumbnail</i>} count={usage ? usage.assets : null} max={plan ? plan.maxAssets : null} />
                        <UsageItem title="Storage" icon={<i className='material-symbols-outlined'>cloud_upload</i>} count={usage ? storageUsed : null} max={plan ? storageMax : null} suffix={false} />
                        {plan && plan.isStandardPlan ? null :
                            <UsageItem title="Journeys" icon={<i className="material-symbols-outlined">fork_right</i>} count={usage ? usage.journeys : null} max={plan ? plan.maxJourneys : null} />
                        }
                        {/* <UsageItem title={t("account.more-details")} icon={<i className='material-symbols-outlined'>more_horiz</i>} count={null} max={null} empty /> */}
                    </div>
                </Card.Body>
            </Card>
            <PricingModal showModal={showModal} handleClose={handleClose} />
        </div>
    );
}
